@import 'variables';
body {
  .report-export-section{
    background: red;
    padding: 10px;
    .export-module-card{
      position: relative;
      z-index: 99;
      box-shadow: 0 2px 4px rgba(0,0,0,0.6);
      overflow: hidden;
      background: #fff;
      margin-bottom: 20px;
      border-radius: 4px;
      .rpt-card-header{
        border-bottom: 1px solid #d7d7d7;
        padding:10px 15px;
        h2{
          font-size: 24px;
        }
      }
      .rpt-card-body{
        padding: 15px;
        .sub-mod-title{
          font-size: 20px;
          margin-bottom: 10px;
        }
        .sub-mod-card{
          margin-left: 10px;
        }
        .export-items{
          margin-bottom: 15px;
          padding-bottom: 15px;
          border-bottom: 1px solid #d7d7d7;
          &:last-child{
            border-bottom: none;
          }
        }
        .export-label{
          margin-bottom: 10px;
          span{
            font-size: 18px;
            padding-left: 6px;
          }
        }
        .export-avial{
          margin-left: 20px;
          h6{
            font-size: 14px;
            margin-bottom: 4px;
          }
          ul{
            display: inline-block;
            li{
              margin-right: 15px;
              label{
                span{
                  padding-left: 6px;
                }
              }
            }
          }
        }
        .filter-avail{
          margin-top: 10px;
          background: #eaeaea;
          margin-left: 20px;
          ul{
            display: inline-block;
          }
        }
      }
    }
  }
  .auth-sec-hd{
    background: transparent;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    padding: 15px 0;
    ul.list-inline{
      float: right;
      li{
        @include elementStyles($size: $font14, $fontFamily: $robotoFamily, $weight: 400, $color: $whiteColor, $margin: 0 0 0 15px);
        &:first-child{
          margin-left: 0;
        }
        &.sign-in{
          margin-left: 8px;
        }
        a{
          @include elementStyles($size: $font14, $fontFamily: $robotoFamily, $weight: 400, $color: $whiteColor, $padding: 5px 15px);
          &.sign-in-link{
            padding-left: 0px;
            font-weight: 600;
          }
        }
        &:last-child{
          a{
            border: 1px solid #fff;
            border-radius: 4px;
          }
        }
      }
    }
  }
  .auth-section{
    background-position: 50%;
    background-repeat: no-repeat;
    display: block;
    padding: 140px 0  15px 0;
    background-image: url('https://sparta-aims.ams3.cdn.digitaloceanspaces.com/others/assets/img/phase-2/banner.jpg');
    background-size: cover;
    min-height: calc(100vh - 120px);
    position: relative;
    .auth-help-text{
      h1{
        @include elementStyles($size: $font36, $fontFamily: $robotoFamily, $weight: 500, $color: $whiteColor, $margin: 0 0 20px 0);
      }
      p{
        @include elementStyles($size: $font24, $fontFamily: $robotoFamily, $weight: 300, $color: $whiteColor, $margin: 0 0 20px 0);
        opacity: 0.63
      }
    }
    .auth-form-wrapper{
      background: #fff;
      padding: 30px;
      box-shadow: 0 2px 6px 0 rgba(0,0,0,.23);
      border-radius: 4px;
      &.lessor-selection-from{
        margin-top: 50px;
      }
      a{
        @include elementStyles($size: 12px, $fontFamily: $robotoFamily, $weight: 400, $color: #0093ff, $margin: 0 0 10px 0);
        display: inline-block;
      }
      .form-title{
        @include elementStyles($size: $font24, $fontFamily: $robotoFamily, $weight: 400, $color: #4f6276, $margin: 0 0 10px 0);
      }
      .form-para{
        @include elementStyles($size: $font16, $fontFamily: $robotoFamily, $weight: 400, $color: #212529, $margin: 0 0 16px 0);
        &.otp{
          font-size: 13px;
          font-weight: bold;
          margin-top: 16px;
        }
      }
      .terms-and-condition{
        @include elementStyles($size: $font14, $fontFamily: $robotoFamily, $weight: 500, $color: #000000, $margin: 0 0 16px 0);
        letter-spacing: 0.5px;
        .url{
          margin: auto;
          font-size: 14px;
          line-height: 1.5;
        }
      }
      form{
        .forgot-pwd-link{
          @include elementStyles($size: $font13, $fontFamily: $robotoFamily, $weight: 400, $color: #0093ff, $margin: 0px 0 15px 0);
          float: right;
        }
        .terms-policy{
          @include elementStyles($size: $font13, $fontFamily: $robotoFamily, $weight: 400, $color: #010101, $margin: 20px 0 20px 0);
          clear: both;
          a{
            color: #0093ff;
          }
        }
        input[type="submit"]{
          @include elementStyles($size: $font14, $fontFamily: $robotoFamily, $weight: 400, $color: $whiteColor, $margin: 10px 0 0 0, $padding: 15px);
          width: 200px;
          line-height: normal;
          letter-spacing: .7px;
          background: #2f8cff;
          border: 1px solid #2670ca;
          border-radius: 3px;
          cursor: pointer;
        }
      }
      .MuiRadio-colorSecondary.Mui-checked{
        color: #3f51b5;
      }
    }
  }
  .features-wrapper{
    padding: 70px 40px;
    background-color: #ffffff;
    .heading-block{
        text-align: center;
        h2{
            @include elementStyles(24px, #7232d4, normal, normal, $ibmplexRegular, null, null);
        }
        h3{
            @include elementStyles(24px, #000000, normal, normal, $ibmplexRegular, null, null);
            margin-top: 5px;
            margin-bottom: 10px;
        }
    }
    .feature-content-block{
      padding: 0 30px;
        .list-inline{
            text-align: center;
            margin-bottom: 80px;
            li{
                display: inline-block;
                a{
                    background-color: #f7f7f7;
                    @include elementStyles(14px, #000000, normal, normal, $ibmplexRegular, null, null);
                    padding: 10px 40px;
                    display: inline-block;
                    width: 235px;
                    border: 1px solid #c2c2c2;
                    vertical-align: -webkit-baseline-middle;
                    &.tab-active{
                        color: #ffffff;
                        border: 1px solid #0a56a8;
                        background-image: linear-gradient(to right, #6538db, #0a56a8);
                        background-image: -webkit-linear-gradient(to right, #6538db, #0a56a8);
                        background-image: -moz-linear-gradient(to right, #6538db, #0a56a8);
                    }
                }
            }
        }
        .tab-content{
            .background-difference{
                padding:40px;
                margin: 0px 0 30px;
                background: #FBF9FE;
                &.last-background{
                    margin: 0px;
                }
              .download-links{
                background: #F4F0FA;
                border-radius: 10px;
                margin-top: 15px;
                margin-left: -15px;
                padding: 15px;
              }
            }
            img{
                max-width: 100%;
            }
            .flex-centered{
                padding-bottom: 40px;
            }
            h4{
                @include elementStyles(24px, #000000, normal, normal, $ibmplexRegular, null, null);
                margin-bottom: 10px;
            }
            p{
                @include elementStyles(16px, #4f6276, normal, normal, $ibmplexRegular, null, null);
              &.card{
                @include elementStyles(12px, #777777, normal, normal, $ibmplexRegular, null, null);
                border: none;

              }

            }
            ul{
              &.list-unstyled{
                padding-left: 35px;
              }
                margin-top: 30px;
                text-align: left;
                margin-bottom: 100px;
                li{
                    @include elementStyles(14px, #000000, normal, normal, $ibmplexRegular, null, null);
                    margin-bottom: 15px;
                    position: relative;
                    padding-left: 15px;
                    &::before{
                        content: '';
                        position: absolute;
                        width: 6px;
                        height: 6px;
                        background-color: #2893FF;
                        border-radius: 50%;
                        left: 0;
                        top: 6px;
                    }
                }
            }
            .wrapper{
              position: absolute;
              width: 100%;
              left: 50%;
              transform: translateX(-50%);
              bottom: 15px;
            }
            .signup{
              bottom: 30px;
            }
            .contact{
              bottom: 30px;
            }
            .download-links{
              background: #F4F0FA;
              border-radius: 10px;
              margin-top: 15px;
              margin-left: -15px;
              padding: 15px;
            }
        }
    }
  }
  .user-management-module-section {
    //user detail list
    .user-detail {
      .section-1 {
        height: 160px;
        position: relative;
        .profile-pic{
          width: 110px;
          height: 110px;
          margin: 20px 0px 0px 34px;
          background-size: cover;
          border-radius: 50%;
          transition: .3s ;
          position: relative;
          .edit-profile-pic {
            display:none;
            position:absolute;
            top:43px;
            left: 40px
          }
          &:hover {
            transform: scale(1.1);
          }
          &:hover .edit-profile-pic{
            display: block;

          }
        }


        .list-basic-data {
          position: absolute;
          top: 1px;
          left: 160px;
          top: 15px;
          .list-item{
            font-size: 12px;
            font-weight: 100;
          }
          .user-name{
            position: absolute;
            font-size: 14px;
          }
        }
        .btn-user-detail{
          float:right;
        }
        .resume{
          margin-top: 37px;
        }
      }
      .resumeNew {
        position: relative;
        right: 6rem;
        top: 2rem;
        float: right;
      }
      .resumeOld {
        position: relative;
        right: -3rem;
        top: 4rem;
        float: right;
      }
      .section-2{
        margin-right: 20px;
        .section-2-list {
          margin: 20px 0px 20px 0px;
        }
      }
    }
    .buttons-cta{
      float:right;
      margin-bottom: 30px;
    }
    .cta-mouse{
      cursor: pointer;
    }
  }
  @media only screen and (max-width: 600px){
  .auth-section{
    padding: 90px 0 50px;
    // min-height: auto;
    .auth-sec-hd{
      ul{
        &.list-inline{
          li{
            font-size: 12px;
            a{
              padding: 5px;
            }
          }
        }
      }
    }
    .auth-help-text{
      br{
        display: none;
      }
      h1{
        font-size: 30px;
      }
      p{
        font-size: 20px;
      }
    }
    .auth-form-wrapper{
      &.lessor-selection-from{
        margin-top: 0px;
      }
      .form-para{
        font-size: 14px;
        margin: 0 0 5px 0;
        br{
          display: none;
        }
      }
      .form-title{
        font-size: 20px;
      }
      .terms-and-condition{
        font-size: 12px;
        br{
          display: none;
        }
        .url{
          font-size: 12px;
        }
      }
    }
  }
}
@media only screen and (max-width: 960px) and (min-width: 600px){
  .auth-section{
    padding: 120px 0 80px;
    // min-height: auto;
  }
  .auth-help-text{
    br{
      display: none;
    }
  }
}
@media only screen and (max-width: 1280px) and (min-width: 960px){

}
@media only screen and (max-width: 1920px) and (min-width: 1280px){

}
@media only screen and (min-width: 1920px){

}
}
