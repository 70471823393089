@import 'variables';
body{
  .mr-calculator-section{
    .mr-title{
      margin-bottom: 16px;
      h1{
        @include elementStyles($size: 16px, $fontFamily: $ibmplexRegular, $color: #bd10e0);
      }
      p{
        @include elementStyles($size: 14px, $fontFamily: $ibmplexRegular, $color: #838383);
      }
    }
    .mr-log-info{
      padding: 10px;
      margin-bottom: 16px;
      ul{
        li{
          margin-right: 15px;
          &:last-child{
            margin-right: 0;
          }
          p{
            @include elementStyles($size: 13px, $fontFamily: $ibmplexRegular, $color: #000000);
          }
          h6{
            @include elementStyles($size: 14px, $fontFamily: $ibmplexSemiBold, $color: #000000);
          }
        }
      }
      // button{
      //   float: right;
      // }
    }
    .asset-card {
      background: #F0F0F1;
      margin-top: 30px;
      -webkit-box-shadow: 0px 0px 4px -1px rgba(0,0,0,0.44);
      -moz-box-shadow: 0px 0px 4px -1px rgba(0,0,0,0.44);
      box-shadow: 0px 0px 4px -1px rgba(0,0,0,0.44);
    }
    .mr-logs-list{
      .new-finance-rpt-cta{
        margin-bottom: 20px;
        .export-and-entry{
          float: right;
          ul.list-inline{
            li.list-inline-item{
              margin-left: 10px;
            }
          }
        }

      }
    }
    .mr-calculator-initiate{
      .assembly-icons{
        margin-bottom: 6px;
        li{
          margin-right: 30px;
          cursor: pointer;
          .assembly-icon{
            position: relative;
            display: inline-block;
            padding: 8px;
            .overlay{
              position: absolute;
              top: 0;
              left: 0;
              height: 100%;
              right: 0;
              z-index: 99;
              background: rgba(0, 0, 0, 0.35);
              svg{
                position: absolute;
                transform: translate(-50%, -50%);
                top: 50%;
                left: 50%;
                z-index: 99;
                font-size: 60px;
                color: #2670CA;
                text-shadow: 0 2px 2px rgba(0,0,0,0.4);
              }
            }
            img{
              width: 40px;
            }
          }

        }
      }
      .mr-initiate-card{
        padding: 15px;
        margin-bottom: 30px;
        position:relative;
        .MuiFormLabel-root {
          letter-spacing: -0.4px;
        }
        .mr-initiate-card-title{
          color:'#bd10e0';
          font-weight: 400;
        }
      }
      .custom-region-variables{
        .action-cta{
          float: right;
          li{
            margin-left: 10px;
          }
        }
        h6{
          @include elementStyles($size: 13px, $fontFamily: $ibmplexLight, $color: #000000, $margin:0 0 8px 0);
        }
        .rangeslider-horizontal{
          height:9px;
          margin: 0;
          box-shadow: 0 0 0;
          .rangeslider__handle{
            width: 18px;
            height: 18px;
            background:#ffffff;
            border:1px solid #bcc4d4;
            box-shadow:0 2px 4px 0 rgba(0,0,0,0.20);
          }
          .rangeslider__handle:after{
            top: 4px;
            left: 4px;
            width:10px;
            height:10px;
            box-shadow: 0 0 0;
            background:#b1bfd0;
          }
          .rangeslider__fill{
            background:#2f8cff;
          }
          .rangeslider__labels{
            display: none;
          }
          .rangeslider__handle-tooltip{
            display: inline-block;
            width: 100px;
          }
        }
      }

    }
    .mr-calculator-intermediate{
      .engine-blended_mr{
        text-align: center;
        background: rgb(222 217 235);
        ul{
          li{
            padding: 10px;
            p{
              @include elementStyles($size: 16px, $fontFamily: $ibmplexRegular, $color: #000000, $margin:0 0 8px 0);
            }
            h6{
              @include elementStyles($size: 16px, $fontFamily: $ibmplexSemiBold, $color: #ffffff);
            }
          }
        }
      }
      .mr-cal-intermediate-section{
        .modifying-cta{
          float: right;
        }
      }
      .mr-intermediate-card{
        .mr-intermediate-card-hd{
          position: relative;
          clear: both;
          padding: 5px 10px;
          h4{
            @include elementStyles($size: 16px, $fontFamily: $ibmplexSemiBold, $color: #bd10e0);
          }
          .assembly-cta-list{
            position: absolute;
            top: 5px;
            right: 5px;
            li{
              margin-left: 10px;
              cursor: pointer;
            }
          }
        }
        .event-info{
          .MuiTypography-colorTextSecondary, .MuiInputAdornment-positionEnd, .MuiInputAdornment-positionStart{
            color: #ffffff;
          }
          .MuiInput-underline:before{
            border-bottom: 1px solid rgba(255, 255, 255, 0.42);
          }
          .MuiInput-underline:after{
            border-bottom: 2px solid #ffffff;
          }
          .MuiIconButton-label{
            color: rgba(255, 255, 255, 0.82);
          }
          input{
            color: #ffffff;
          }

        }
        margin-bottom: 15px;
        .basic-info{
          padding: 15px;
          position: relative;
          clear: both;
          .engine-alert{
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            text-align: center;
            background: #fff3cd;
            border: 1px solid #ffeeba;
            @include elementStyles($size: 13px, $fontFamily: $ibmplexSemiBold, $color: #856404);
            span{
              text-decoration: underline;
              color: #3f51b5;
              cursor: pointer;
            }
          }
        }
        .event-info{
          padding: 15px;
          background-image: linear-gradient(90deg,#2c45c1,#965fdeed);

          .event-card{
            margin-bottom: 16px;
            h4{
              @include elementStyles($size: 14px, $fontFamily: $ibmplexBold, $color: #ffffff, $margin:0 0 8px 0);
              text-decoration: underline;
            }
            &:last-child{
              margin-bottom: 0;
            }
          }
          label{
            color: #d7d7d7;
          }
          .label-value-card{
            p{
              color: #d7d7d7;
            }
            h6{
              color: #ffffff;
            }
          }
        }
        .mr-info{
          padding:5px 15px;
          background: #2831757d;
          table{
            width: 100%;
            th,td{
              padding: 4px;
              word-wrap: break-word;
              word-break: break-all;
            }
            th{
              @include elementStyles($size: 14px, $fontFamily: $ibmplexRegular, $color: #ffffff);
            }
            td{
              @include elementStyles($size: 14px, $fontFamily: $ibmplexSemiBold, $color: #ffffff);
              &.lower-rate{
                color: #bd2238;
              }
              &.target-rate{
                color: #46e82a;
              }
              &.upper-rate{
                color: #FFD233;
              }
            }
          }
        }
      }
      .shop-visit-card{
        margin-bottom: 15px;
        background: #F0F0F1;
        padding:15px 15px 0 15px;
        position: relative;
        max-height: 100px;
        transition: max-height 0.1s linear;
        .differentiation-values{
          h6{
            @include elementStyles($size: 14px, $fontFamily: $ibmplexSemiBold, $color: #000000);
          }
          p{
            @include elementStyles($size: 13px, $fontFamily: $ibmplexRegular, $color: #000000);
            text-decoration: line-through;
          }
        }
        h2{
          @include elementStyles($size: 16px, $fontFamily: $ibmplexRegular, $color: #bd10e0);
          position: absolute;
          top: 5px;
          left: 5px;
        }
        .restricted-llp{
          @include elementStyles($size: 12px, $fontFamily: $ibmplexRegular, $color: #f44337);
        }
        .label-title{
          @include elementStyles($size: 13px, $fontFamily: $ibmplexRegular, $color: #000000);
        }
        h6{
          @include elementStyles($size: 16px, $fontFamily: $ibmplexRegular, $color: #000000);
        }
        .shop-visit-head{
          position: relative;
          .sv-arrow-icon{
            position: absolute;
            right: 10px;
            top: 35%;
            z-index: 99;
            cursor: pointer;
            transition: .3s ease
          }
          .sv-arrow-icon-down {
            transform: rotate(90deg);

          }
        }
        .shop-visit-body{
          background: #e4e4e4;
          padding: 10px 15px;
          animation: 5s linear .1s infinite alternate;
          h4{
            @include elementStyles($size: 16px, $fontFamily: $ibmplexRegular, $color: #bd10e0, $margin: 0 0 10px 0);
          }
          .total-sv{
            text-align: center;
            background: #cecece;
            ul{
              li{
                padding: 10px;
                p{
                  margin-bottom: 6px;
                }
              }
            }
          }
        }
      }
      .shop-visit-card-down{
        max-height: 500px;
        transition: max-height .2s linear;
      }
    }
    .mr-graphical-projection{
      padding-bottom: 40px;
      .mnt-event-head{
        padding: 10px;
        @include elementStyles($size: 14px, $fontFamily: $ibmplexRegular, $color: #BD10E0);
      }
      .differentiation-values{
        h6{
          @include elementStyles($size: 14px, $fontFamily: $ibmplexSemiBold, $color: #000000);
        }
        p{
          @include elementStyles($size: 13px, $fontFamily: $ibmplexRegular, $color: #000000);
          text-decoration: line-through;
        }
      }
      .simulate-label{
        @include elementStyles($size: 14px, $fontFamily: $ibmplexRegular, $color: #a0a0a0);
      }
      .fund-status-card{
        ul{
          li{
            margin-right: 10px;
            margin-bottom: 10px;
            .fund-card{
              padding: 15px 10px;
              background-image: linear-gradient(to bottom, #3353F6 , #96ADFF);
              p{
                @include elementStyles($size: 14px, $fontFamily: $ibmplexRegular, $color: #d7d7d7);
              }
              h4{
                @include elementStyles($size: 18px, $fontFamily: $ibmplexRegular, $color: #ffffff);
              }
            }
          }
        }
      }
      .mnt-table-list{
        margin-bottom: 15px;
        position: relative;
        .view-more-events{
          position: absolute;
          right: 10px;
          top: 0;
          z-index:99;
          display: inline-block;
          padding: 5px;
          @include elementStyles($size: 13px, $fontFamily: $ibmplexSemiBold, $color: #2f8cfe);
          text-decoration: underline;
          cursor: pointer;
        }
        .table-mn-hd{
          padding: 10px;
          border-radius: 0;
          @include elementStyles($size: 14px, $fontFamily: $ibmplexSemiBold, $color: #000000);
        }

      }
      .engine-events-card{
        .location-search-input{
          border-bottom: 1px solid #d7d7d7 !important;
        }
        .engine-basic-info{
          padding: 15px;
          h4{
            @include elementStyles($size: 14px, $fontFamily: $ibmplexSemiBold, $color: #bd10e0, $margin: 0 0 10px 0);
          }
        }
        .engine-events-table{
          tr{
            .wk-title{
              padding-left: 5px;
              margin-bottom: 3px;
              display: inline-block;
              text-decoration: underline;
              @include elementStyles($size: 14px, $fontFamily: $ibmplexSemiBold, $color: #000000);
            }
            .shop-visit-icon{
              float: left;
            }
            ul{
              .wk-type-name{
                @include elementStyles($size: 14px, $fontFamily: $ibmplexSemiBold, $color: #000000);
              }
            }
          }
        }
      }
      .projection-simulations-cta{
        position: fixed;
        bottom: 0;
        left: 0;
        right: 0;
        padding: 10px;
        z-index: 100;
        background: #ffffff;
        box-shadow: 0 2px 4px rgba(0,0,0,0.5);
        ul{
          float: right;
          li{
            cursor: pointer;
            margin-left: 15px;
            &:first-child{
              margin-left: 0;
            }
          }
        }
      }
      .life-on-wings{
        background: #ffffff;
        padding: 15px;
        h6{
          @include elementStyles($size: 16px, $fontFamily: $ibmplexRegular, $color: #000000);
        }
      }
    }
    .mr-cashflow-listing{
      h1{
        margin-bottom: 16px;
        a{
          @include elementStyles($size: 18px, $fontFamily: $ibmplexRegular, $color: #bd10e0);
          padding-right: 10px;
        }
      }
      .cashflow-filters{
        li{
          min-width: 240px;
          margin-left: 15px;
        }
      }
      .cashflow-header{
        background: #fafafa;
        border: 1px solid #dfe3e6;
        span{
          padding: 15px 10px;
          display: inline-block;
          @include elementStyles($size: 14px, $fontFamily: $ibmplexSemiBold, $color: #6d9bb9);
        }
      }
      .cashflow-list{
        &:nth-child(even){
          background: #f5f7fa;
        }
        &:nth-child(odd){
          background: #ebeff6;
        }
        .monthly-total{
          p{
            @include elementStyles($size: 13px, $fontFamily: $ibmplexSemiBold, $color: #000000);
          }
        }
        .cashflow-row{
          .first-card{
            background: #ffffff !important;
          }
          .month-block{
            border-top: 1px solid #dfe3e6;
            padding: 15px 15px 5px  15px;
          }
          .border-col{
            border-left: 1px solid #dfe3e6;
          }
          .cash-info{
            padding: 15px;
            border-bottom: 1px solid #dfe3e6;
          }
        }
        &:first-child{
          .month-block{
            border-top: none;
          }
        }
        &:last-child{
          .first-card{
            border-bottom: 1px solid #dfe3e6 !important;
          }
        }
      }
    }
  }
  // Modal Styling
  .workscope-moreinfo-modal{
    h4{
      @include elementStyles($size: 16px, $fontFamily: $ibmplexRegular, $color: #bd10e0, $margin:0 0 12px 0);
    }
  }
  .mr-initiate-card{
    padding: 15px;
  }
  .engine-derate-modal{
    .loi-row{
      margin-bottom: 18px;
      label{
        @include elementStyles($size: 16px, $fontFamily: $ibmplexRegular, $color: rgba(0, 0, 0, 0.54));
      }
      span{
        color: rgb(255, 193, 7);
        font-size: 12px;
      }
      table{
        width: 100%;
        border: 1px solid #e1e1e1;
        tr{
          td{
            border-right: 1px solid #e1e1e1;
            text-align: center;
            padding: 10px 0;
            &:last-child{
              border-right: none;
            }
            &.base-ratio{
              background: #b0ccea;
            }
            &.selected{
              background: #4A90E2;
              color: #ffffff;
            }
          }
        }
      }
    }
  }
  .derate-matrix-blk-modal{
    table{
      width: 100%;
      border: 1px solid #6794c8;
      thead{
        th{
          background: #afcbe9;
          padding: 10px;
          border: 1px solid #6794c8;
          font-family:$ibmplexRegular;
          font-size:14px;
        }
      }
      tbody{
        td{
          padding: 10px;
          border: 1px solid #6794c8;
          font-family:$ibmplexRegular;
          font-size:14px;
          &:first-child{
            background: #afcbe9;
            width: 140px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 600px){
    .mr-calculator-section{
      .mr-logs-list{
        .new-finance-rpt-cta{
          .export-and-entry{
            float: left;
            .list-inline{
              .list-inline-item{
                &:first-child{
                  margin-left: auto;
                }
                .MuiButton-text{
                  &:first-child{
                    padding-left: 0
                  }
                }
              }
            }
          }
        }
      }
      .mr-title{
        h1{
          font-size: 12px;
        }
      }
      .mr-calculator-initiate{
        .mr-initiate-card{
          margin-bottom: 20px;
          &:last-child{
            margin-bottom: auto;
          }
        }
        .metrics{
          margin-bottom: 5px;
        }
      }
      .mr-calculator-intermediate{
        .mr-log-info{
          .update-engine-label{
            text-align: left;
            padding-top: 5px;
          }
          .list-inline{
            .list-inline-item{
              min-width: 100px;
              margin-bottom: 8px;
            }
          }
        }
        .mr-intermediate-card{
          &:last-child{
            margin-bottom: auto;
          }
          .event-info{
            .MuiInput-formControl{
              margin-top: 25px
            }
          }
          .mr-info{
            overflow: auto;
            white-space: nowrap;
          }
        }
        .shop-visit-card{
          margin-bottom: 10px;
          max-height: none;
          &:last-child{
            // margin-bottom: auto;
          }
          h2{
            padding-left: 8px;
          }
          h6{
            font-size: 14px;
          }
          .shop-visit-head{
            padding: 15px 0 10px 0;
          }
          .shop-visit-body{
            .MuiTypography-body1{
              font-size: 13px;
            }
            .mr-edit{
              display: inline-flex;
            }
            .total-sv{
              margin-top: 5px;
            }
          }
        }
        .shop-visit-card-down {
          max-height: none;
        }
        .mr-cal-intermediate-section{
          .basic-info{
            margin-bottom: 25px;
            .llp-csn{
              margin: 5px 0 3px 0;
              font-size: 14px;
            }
            .engine-alert{
              bottom: -25px;
            }
            span{
              font-size: 14px;
            }
          }
        }
        .engine-blended_mr{
          ul{
            li{
              p{
                font-size: 13px;
              }
            }
          }
        }
      }
      .mr-graphical-projection{
        padding-bottom: 10px;
        .projection-simulations-cta{
          z-index: 99;
          padding-left: 15px;
          ul{
            float: left;
          }
        }
        .MuiTabs-scrollButtons{
          width: 0 !important;
        }
        .mnt-table-list{
          .assembly-event-hd-mobile{
            font-size: 12px;
            color: #BD10E0;
            margin-bottom: 3px;
          }
        }
        .engine-events-card{
          .engine-events-table{
            tr{
              ul{
                .wk-type-name{
                  white-space: nowrap;
                }
              }
            }
          }
        }
        .simulate-label{
          font-size: 13px;
        }
        .life-on-wings{
          h6{
            font-size: 14px;
          }
        }
        .fund-status-card{
          ul{
            li{
              .fund-card{
                padding: 10px;
                h4{
                  font-size: 14px;
                }
              }
            }
          }
        }
      }
      .mr-cashflow-listing{
        .cashflow-list{
          .cashflow-row{
            .cash-info{
              font-size: 13px;
              padding: 10px;
              display: inline-block;
              border-bottom: none;
              .cashflow-event-hd-mobile{
                color: #6d9bb9;
                font-size: 12px;
                margin-bottom: 2px;
              }
            }
            .month-block{
              padding: 20px 10px 5px 10px;
              color: #BD10E0;
              .monthly-total{
                margin-top: 5px;
                span{
                  color: #6d9bb9;
                }
              }
            }
          }
        }
        .cashflow-header{
          span{
            padding: 8px;
            font-size: 12px;
          }
        }
        .cashflow-filters{
          border-bottom: 1px solid #dfe3e6;
          padding-bottom: 15px;
        }
      }
      .generate-report-button{
        margin-top: 10px;
        float: right;
      }
    }
    .engine-derate-modal{
      .MuiInput-formControl{
        margin-top: 22px;
      }
      .MuiFormLabel-root, .MuiInputBase-input{
        font-size: 14px;
        white-space: nowrap;
      }
      .loi-row{
        span{
          white-space: nowrap;
        }
      }
    }
  }
  @media only screen and (min-width: 601px) and (max-width: 960px){
    .mr-calculator-section{
      .mr-log-info{
        .MuiButton-root{
          float: right;
        }
      }
      .mr-calculator-intermediate{
        .mr-log-info{
          .MuiButton-root{
            float: right !important;
            margin-top: auto;
          }
        }
        .shop-visit-card{
          max-height: none;
          .shop-visit-head{
            margin-top: 15px;
          }
          .shop-visit-body{
            margin-top: 10px
          }
        }
        .shop-visit-card-down{
          max-height: none;
        }
        .list-inline{
          .list-inline-item{
            margin-bottom: 8px;
          }
        }
      }
      .mr-calculator-initiate{
        .metrics{
          margin-bottom: 5px;
        }
        form{
          .map-section{
            input{
              width: 50%!important;
            }
          }
        }
      }
      .mr-graphical-projection{
        .projection-simulations-cta{
          z-index: 99;
          padding-left: 15px;
          ul{
            float: left;
          }
        }
        .MuiTabs-scrollButtons{
          width: 0 !important;
        }
        .mnt-table-list{
          .assembly-event-hd-mobile{
            font-size: 12px;
            color: #BD10E0;
            margin-bottom: 3px;
          }
        }
      }
      .mr-cashflow-listing{
        .cashflow-list{
          .cashflow-row{
            .cash-info{
              font-size: 13px;
              padding: 10px;
              display: inline-block;
              border-bottom: none;
              .cashflow-event-hd-mobile{
                color: #6d9bb9;
                font-size: 12px;
                margin-bottom: 2px;
              }
            }
            .month-block{
              padding: 20px 10px 5px 10px;
              color: #BD10E0;
              .monthly-total{
                margin-top: 5px;
                span{
                  color: #6d9bb9;
                }
              }
            }
          }
        }
        .cashflow-header{
          span{
            padding: 8px;
            font-size: 12px;
          }
        }
        .cashflow-filters{
          border-bottom: 1px solid #dfe3e6;
          padding-bottom: 15px;
        }
      }
      .generate-report-button{
        margin-top: auto;
      }
    }
  }
}
